import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)



export default new Vuex.Store({
  state: {
    refreshFlag: 0 // 控制刷新的标记
  },
  getters: {
  },
  mutations: {
    setRefreshFlag(state) {
      state.refreshFlag += 1; // 每次调用mutation，标记加1
      console.log('标记', state.refreshFlag );
      
    }
  },
  actions: {
  },
  modules: {
  },
  plugins: [
  ],
})
